import http from '@/api/http'
export default {

      async setAllOrders({commit,state},obj) {
                  
            let filter_params = {}
            state.excel_datas = filter_params
            // console.log(obj, 'obkeee');
            // for (let key in obj) {
            //       if (obj[key] === null) {
            //         delete obj[key];
            //       }
            //     }
            //     console.log(obj, 'last answer');
            try {
                  let response = await http.get('order-document/',{
                        params: obj
                  })
                  let result = await response.data
                  console.log("work");
                  console.log(obj, "obj");
                  
                  commit('SET_ORDER',result.data)  
                  commit("SET_PAGINATION", result.pagination)
            } catch (error) {
                        console.log(error)
            }
           
        },
      
      async updateOrder({commit,dispatch }, obj) {
          
            try {
                  const response = await http.put(`order-document/?id=${obj.order.id}`, obj.order)
                  const result = await response.data
                  commit('UPDATE_ORDER', result.data)
                  dispatch('setLastDays')
                  obj.cb()
            } catch (error) {
                  console.log(error)
            }
          
      },
      async setLastDays({commit}) {
            try {
                  const response = await http.get(`order-notify/`)
                  const result = await response.data
                  commit('SET_NOTIFY_ORDER', result.data)
            } catch (error) {
                  console.log(error)
            }
      },
        exportExcel({state}, data) {
            console.log(data.company);
            let filter_params = state.excel_datas
            http.get('export_excel/',{
                  responseType: 'blob',
                  params: {...filter_params, company: data.company}
              }).then((response) => {
                   let fileURL = window.URL.createObjectURL(new Blob([response.data]));
                   let fileLink = document.createElement('a');
 
                   fileLink.href = fileURL;
                   fileLink.setAttribute('download', 'file.xlsx');
                   document.body.appendChild(fileLink);
 
                   fileLink.click();
              }).catch((error) => {
                  console.log(error)
              });
        }

}