import axios from 'axios'
import baseURL from './server_url'

function getLocalAccessToken() {
    const accessToken = window.localStorage.getItem("accessToken");
    return accessToken;
  }
  
  function getLocalRefreshToken() {
    const refreshToken = window.localStorage.getItem("refreshToken");
    return refreshToken;
  }


const instance = axios.create({
    baseURL:`${baseURL}/api/institut/`,
    timeout: 6000,
})

instance.interceptors.request.use((config) => {
    
    const token = getLocalAccessToken()

    if (token) {
        config.headers['Authorization'] = `Bearer ${token}` 
    }

    return config

},function(error) {
    console.log(error)
    return Promise.reject(error)
})

instance.interceptors.response.use((response) => {
    return response
}, async (error) => {
    const originalConfig = error.config;
    if (error.response) {
        if (error.response.status === 401  && !originalConfig._retry) {
            originalConfig._retry = true 
            try {
                const rs = await refreshToken()
                const {access} = rs.data

                window.localStorage.setItem("accessToken", access)
                instance.defaults.headers.common["Authorization"] = access;
                return instance(originalConfig)                
            } catch (_error) {
                if (_error.response && _error.response.data) {
                  if (!_error.response.data.hasOwnProperty('messages') ) {
                    localStorage.removeItem('accessToken')

                    localStorage.removeItem('refreshToken')
                    localStorage.removeItem('role')
                    
                  }
                    return Promise.reject(_error.response.data);
                  }
                  return Promise.reject(_error);
            }
          }        
        
    }
    return Promise.reject(error)
})

function refreshToken() {
  // https://api.med.frank96.uz/api/login/refresh/
  // console.log(`${baseURL}/api/login/refresh/`)
    return axios.post(`${baseURL}/api/login/refresh/`, {
      refresh: getLocalRefreshToken(),
    });
   
  }

export default instance